
angular
    .module('aq-main',
        [
            'accounts',
            'aq.admin',
            'aq.auth',
            'aq.energyStarApi',
            'aq.filters.formatters',
            'aq.filters.transformers',
            'aq.filters',
            'aq.messages',
            'aq.publicDisplay',
            'aq.services.cache',
            'aq.services.errors',
            'aq.services.snapshot',
            'aq.services.user',
            'aq.services',
            'aq.settings',
            'aq.ui.loading',
            'aq.ui',
            'dashboard',
            'deviceManagement',
            'projectCenter',
            'tenantBilling',
            'energyInsights',
            'home',
            'meterManagement',
            'mgcrea.ngStrap',
            'notificationCenter',
            'pascalprecht.translate',
            'properties',
            'registration',
            'reportCenter',
            'restangular',
            'senseware',
            'templates-app',
            'ui.router',
            'user',
            'aq.reports',
            'ng-sortable',
            'ngMockE2E',
            'aq.utilityBudgets',
            'projects',
            'networkingDevices',
            'pointTrending',
            'centralPlant'
        ])
    .config(($stateProvider, $locationProvider, $translateProvider, RestangularProvider, $windowProvider, $compileProvider) => {
        const $w = $windowProvider.$get();
        // eslint-disable-next-line no-underscore-dangle
        $compileProvider.debugInfoEnabled($w.__isProd);
        RestangularProvider.setBaseUrl('/api/v1');
        $stateProvider
            .state('aq', {
                abstract: true,
                templateUrl: 'app/main/main.html',
                controller: 'Aquicore',
                controllerAs: 'vm',
                data: {
                    goBack: {}
                },
                resolve: {
                    authToken(
                        AuthorizationService: aq.services.AuthorizationService,
                        $window: ng.IWindowService,
                        RestangularV3FullResponse: restangular.IService,
                        RestangularV3: restangular.IService,
                    ) {
                        return AuthorizationService.getTokenSilently().then((token) => {
                            if (!token) {
                                AuthorizationService.logout($window.location.href);
                            }
                            RestangularV3FullResponse.setDefaultHeaders({ authorization: `Bearer ${token}` });
                            RestangularV3.setDefaultHeaders({ authorization: `Bearer ${token}` });
                            RestangularProvider.setDefaultHeaders({ authorization: `Bearer ${token}` });
                            $window.sessionStorage.setItem('bearer-token', token);
                            // if you tried to load a page before you authenticated we store it.
                            // now we check if it's there and redirect if so!
                            const url = $window.localStorage.getItem('url');
                            if (url) {
                                $window.localStorage.removeItem('url');
                                $window.location.href = url;
                            }
                            return token;
                        }).catch(() => {
                            AuthorizationService.logout($window.location.href);
                        });
                    },
                    waitForAuthToken(allAppsLoaded, authToken) {
                        return authToken;
                    },
                    initUser(authToken: string, Restangular: restangular.IService, Auth: aq.services.Auth): ng.IPromise<any> {
                        // init auth if you can for deep links
                        return Restangular.one('users', 'me')
                            .get({ inflate: 'currentProfile,currentProfile.apps' })
                            .then((user) => {
                                Auth.init(user.currentProfile.apps);
                                return user;
                            }, (error) => {
                                return null;
                            });
                    },
                    accountId(authToken, Auth, initUser): number {
                        if (initUser) {
                            return initUser.currentProfile.account;
                        }
                        return null;
                    },
                    initPermissions(Auth: aq.services.Auth, accountId: number, authToken) {
                        if (accountId) {
                            return Auth.initializePermissions(accountId);
                        }
                    },
                    appRestructureRedirect(
                        initPermissions,
                        $window: ng.IWindowService,
                        RedirectService: aq.services.RedirectService,
                        $q: ng.IQService,
                        Auth: aq.services.Auth,
                        $stateParams: ng.ui.IStateParamsService
                    ) {
                        const isRedirect = Auth.hasFunctionality('Force App Restructure Redirect');
                        const { pathname, search } = $window.location;
                        const queryParams = search ? `${search}&isMonolithRedirect=true` : '?isMonolithRedirect=true';
                        const isInsideIframe = $window.self != $window.top;
                        const isReportPage = $window.location.pathname.startsWith('/reports/') || $window.location.href.indexOf("snapshot") > -1;
                        if (isRedirect && !isInsideIframe && !isReportPage) {
                            if ($stateParams.switchAccountTrigger !== null) {
                                // result of manually switching to account with redirect, should be redirected to the homepage instead
                                RedirectService.redirectWithoutParams('');
                            } else {
                                let redirectUrl = RedirectService.getFrontendRedirectUrl(pathname);
                                if (redirectUrl) {
                                    if (redirectUrl.startsWith('/')) {
                                        redirectUrl = redirectUrl.substring(1);
                                    }
                                    RedirectService.redirectWithoutParams(redirectUrl);
                                } else {
                                    // if unable to map to the new frontend route, redirect as-is
                                    // (alternatively, redirect to predefined frontend route, i.e. /custom?url=current_monolith_url)
                                    RedirectService.redirectWithoutParams(pathname.substring(1) + queryParams);
                                }
                            }

                            return $q.defer().promise;
                        }
                    },
                    allAppsLoaded(Auth: aq.services.Auth, initPermissions: any[], authToken) {
                        return Auth.allAppsWithIds;
                    }
                },
                params: {
                    switchAccountTrigger: null
                }
            })
            .state('register', {
                url: '/register/new',
                templateUrl: 'app/main/registration/register.html',
                controller: 'RegisterCtrl as registerCtrl'
            })
            .state('acceptInvitation', {
                url: '/acceptInvitation?token?id?accountName',
                templateUrl: 'app/main/invitation/accept.html',
                controller: 'InvitationAcceptCtrl as invitationAcceptCtrl'
            })
            .state('invalidInvitation', {
                url: '/invalidInvitation',
                templateUrl: 'app/main/invitation/invalid.html',
                controller: 'InvitationAcceptCtrl as invitationAcceptCtrl'
            })
            .state('aq.buildingChooser', {
                templateUrl: 'app/main/buildingList.html',
                controller: 'BuildingsListCtrl',
                params: {
                    originState: null,
                    buildingId: null
                },
                data: {
                    appName: 'Select Building'
                }
            })
            .state('aq.externalLoading', {
                url: '/external-loading',
                template: '<div class="loading-spinner-external"><img src="/img/noda-symbol-purple.png" class="spinner aquispin" /></div>',
                controller: ($window: ng.IWindowService, $localStorage) => {
                    $window.top.postMessage({ type: 'monolith-loaded' }, '*');
                }
            });

        const url = `${$w.location.protocol}//${$w.location.host}/api/v3/languages/`;
        $translateProvider.useStaticFilesLoader({
            prefix: url,
            suffix: '.json'
        });

        $translateProvider.useSanitizeValueStrategy('escaped');
        $translateProvider.preferredLanguage('en_us');
        $translateProvider.fallbackLanguage('en_us');

        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false
        });
    })
    .run(($rootScope, $state, $stateParams, Auth: aq.services.Auth, HTTPCache, $translate, $aq, Messages, Segment, $location) => {
        HTTPCache.init();
        $rootScope.Auth = Auth;
        $rootScope.$state = $state;
        $rootScope.$stateParams = $stateParams;
        $rootScope.$aq = $aq;
        $rootScope.breadcrumbs = [];

        const setNavigationState = function (toState, toParams, fromState, fromParams, options) {
            $rootScope.$navigationState = {
                state: toState,
                previousState: fromState.name,
                currentState: $state.$current,
                params: $stateParams,
                hideNavbar: $state.$current.data ? $state.$current.data.hideNavbar : false,
                getAppName(state) {
                    let root = '';
                    if (state.data && state.data.appName) {
                        root = state.data.appName;
                        if (state.data.appName === 'Optimization') {
                            root = 'Load Analytics';
                        }
                        if (state.data.appName === 'Point Trending') {
                            root = 'Equipment Analytics';
                        }
                    }
                    if (state.name.match(/aq.properties/)) {
                        root = 'Settings';
                    }

                    return $translate.instant(root);
                },
                getDisplayName(state) {
                    return $translate.instant(state.data.breadcrumb);
                },
                shouldHide(state) {
                    return !!state.data.hide;
                }
            };
        };


        $rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams, options) => {
            setNavigationState(toState, toParams, fromState, fromParams, options);
        });

        $rootScope.$on('$stateChangeError', (evt, toState, toParams, fromState, fromParams, error) => {
            if ($location.path().startsWith('/reports/')) {
                const input = $('<input type="hidden" id="DOCRAPTOR_DOCUMENT_ERROR" />')[0] as HTMLInputElement;
                const errorInfo = JSON.stringify(error);
                input.setAttribute('value', `State Loading Error: ${errorInfo}`);
                $('body').append(input);
            } else {
                Messages.error('There was an error navigating to the page. Please try again.');
            }
        });
    })
    /** *
     * use this service as a promise when OptionsService or Account object
     * needs to be initialized/resolved, refer to resolve in AqMainCtrl
     */
    .service('mainAppInitialized', ($q: ng.IQService, RestangularV3FullResponse: restangular.IService) => {
        return $q.defer();
    })
    .factory('AuthToken', ($window) => {
        return $window.sessionStorage.getItem('bearer-token');
    })
    .factory('RestangularV3', (Restangular: restangular.IService, $window) => {
        return Restangular
            .withConfig((RestangularConfigurer) => {
                RestangularConfigurer.setBaseUrl('/api/v3');
                RestangularConfigurer.setRequestInterceptor((element, operation) => {
                    if (operation === 'remove') {
                        return undefined;
                    }
                    if (element) {
                        delete element.originalElement;
                        return element;
                    }
                });
            });
    })
    .factory('RestangularV3FullResponse', (RestangularV3: restangular.IService, $window: ng.IWindowService) => {
        const r = RestangularV3.withConfig(() => { });
        r.setFullResponse(true);
        return r;
    })
    .factory('subdomain', ($location): string => {
        const host = $location.host();
        if (host.indexOf('.') < 0) {
            return null;
        }
        return host.split('.')[0];
    })
    .factory('AQ_API_URL', (subdomain) => {
        if (subdomain && ['my', 'reports-api'].indexOf(subdomain) > -1) {
            return 'https://api.aquicore.com/';
        }
        return 'https://stageapi.aquicore.com/';
    })
    .factory('ENTIC_HOST_URL', (subdomain) => {
        if (subdomain && subdomain === 'my') {
            return 'https://my.entic.com/';
        }
        return 'https://qa-portal3.entic-int.com/';
    })
    .constant('_', (window as any)._)
    // use lodash in templates, ng-repeat="x in _.range(3)"
    .run(($rootScope) => {
        $rootScope._ = (window as any)._;
    });
